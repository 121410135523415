var appointment = function(callcenter) {
	var appointment = this;

	this.calendar =  undefined;
	this.callcenter = callcenter;
	this.map = undefined;
	this.manager = 0;
	this.area = localStorage.getItem("area");
	this.agentView = 'agendaDay';

	this.init = function() {
		if(this.map) {
			this.map.remove();
			this.map = undefined;
		}

		this.bindEvents();

		// ogni minuto aggiorno il calendario così se ci sono modifiche me ne posso accorgere
		setInterval(this.refreshCalendar, 60000);

		if(this.area && this.callcenter) {
			console.log(this.area);
			var idArea = $('#id_area').val() || 0;
			if (idArea == 0)
				$('#id_area').val(this.area);
			$('#id_area').trigger('change');
		}
	};

	this.bindEvents = function() {
		this.calendar = $('#calendar').fullCalendar({
			firstDay: 1,// change first day of week
			weekends: true,
			defaultView: (appointment.callcenter) ? 'agendaWeek' : appointment.agentView,
			displayEventTime: false,
			slotDuration: "00:30:00",
			minTime: "07:00:00",
			maxTime: "19:00:00",
			buttonHtml: {
				prev: '<i class="ace-icon fa fa-chevron-left"></i>',
				next: '<i class="ace-icon fa fa-chevron-right"></i>'
			},
			header: {
				left: 'prev,next today',
				center: 'title',
				right: 'month,agendaWeek,agendaDay'
			},
			events: {
				url: app.baseUrl + '/appointment',
				type: 'GET',
				data: function () { // a function that returns an object
					return {
						list: 1,
						manager: appointment.manager,
						area: appointment.area,
						visuale: appointment.calendar ? appointment.calendar.fullCalendar('getView').type : (appointment.callcenter) ? 'agendaWeek' : appointment.agentView
					};
				},
				success: appointment.onSuccess,
				error: function () {
					console.error('Errore recuperando gli eventi del calendario');
				}
			},
			eventAfterAllRender: appointment.onSuccessEvents,
			editable: false,
			droppable: false, // this allows things to be dropped onto the calendar !!!
			selectable: true,
			selectHelper: true,
			select: function (start) {
				if(appointment.callcenter) {
					appointment.edit(0, start);
				}
			},
			eventClick: function (calEvent) {
				if(calEvent.click) appointment.edit(calEvent.id);
			}
		});

		$('[data-interaction=submitForm]').unbind('click').bind('click', function(e) {
			var url = $(this).attr("data-url");
			var form = $(this).attr("data-form");
			var forceAgent = $(this).attr("data-force");
			var forceAppointment = $(this).attr("data-forceAppointment");

			var is_contracted = $("#sel_is_contracted:enabled").val();
			var n_elementi = 0;
			var check_ok = true;

			if (is_contracted == 1) {
				//contrattualizzato
                $('tbody.tbody_contract_values tr').each(function() {
                    $(this).find(".input_number_contract_class").each(function() {
                    	if (isNaN(parseInt($(this).val()))) {
                    		app.error("Il numero di contratti deve essere un numero intero");
                            check_ok = false;
						} else {
                            n_elementi = parseInt(n_elementi) + parseInt($(this).val());
                        }
                    });

                });
                if (n_elementi == 0) {
                    app.error("Il numero di contratti è 0, se il cliente è contrattualizzato bisogna inserire almeno un contratto");
                    check_ok = false;
				}
			}

			if (check_ok) {

                var success = function (data) {
                    if (data && data.length) {
                        $('#lat').val(data[0].Y);
                        $('#lng').val(data[0].X);
                    }
                    app.formSubmit(url, form, {forceAgent: forceAgent}, function (data) {
                        if (data.response) {
                            // app.success("", "Appuntamento creato con successo");
							if (typeof(data.error) != "undefined" && data.error !== null && data.error != "")
								app.warning("",data.error);

                            app.href(url + "/" + data.message); //+ "/?forceAppointment="+forceAppointment
                        } else {
                            app.warning("", data.message);
                        }
                        app.block(0);
                    }, function () {
                        app.block(0);
                    });
                };

                app.block(1);
                // Prima se non già eseguito faccio il geocoding
                var lat = $('#lat').val();
                var lng = $('#lng').val();

                if (lat && lng) {
                    success();
                } else {
                    var indirizzo = $('#address').val();
                    var zip = $('#zip').val();
                    var city = $('#city').val();
                    var province = $('#province').val();
                    var region = $('#region').val();

                    var address = indirizzo + ", " + zip + ", " + city + ", " + province + ", " + region;
                    map.geocoding(address, function (data) {
                        success(data);
                    });
                    setTimeout(function () {
                        success([]);
                    }, 5 * 1000);
                }
            }
		});

		$('[data-interaction=posticipa]').unbind('click').bind('click', function() {
			var url = $(this).attr('data-url');
			var id = $(this).attr('data-id');
			var isDone = $(this).attr('data-is-done');
			var cambia_stato = 0;

			if (isDone != 0) {
				if (!confirm("E' possibile posticipare un appuntamento solo se in stato di attesa, riportare allo stato di attesa e procedere?"))
					return;
				else
					cambia_stato = 1;
			}


			app.href(url + "?id="+ id +"&cambia_stato="+ cambia_stato);
		});

		$('[data-interaction=deleteAppointment]').unbind('click').bind('click', function() {
			var url = $(this).attr('data-url');
			var id = $(this).attr('data-id');

			if(!confirm("Eliminare l'appuntamento?")) {
				return;
			}

			app.block(1);
			$.delete(url + "/" + id)
				.success(function(data) {
					if(data.response) {
						app.href("#dashboard");
					} else {
						app.warning("", data.message);
					}
					app.block(0);
				})
				.error(function() {
					app.error("", "Eliminazione fallita");
					app.block(0);
				});
		});

		$('#id_area').unbind('change').bind('change', function() {
			var url = $(this).attr("data-url");
			var idArea = parseInt($(this).val());
			var sel = parseInt($('#id_agent').attr("data-sel"));
			var force = parseInt($(this).attr("data-force"));

			if(idArea) {
				app.blockUI(1);
				$.get(url + "/" + idArea + "?force=" + force)
					.success(function(data) {
						//console.log(data);
						if(data.response && Array.isArray(data.message)) {
							$('#id_agent').html("");
							$.each(data.message, function(i, v) {
								var $option = $('<option value="' + v.id + '">' + v.name + '</option>');
								$('#id_agent').append($option);
							});
							$('#id_agent').removeAttr("disabled");
							if(sel) $('#id_agent').val(sel);
						}
						app.blockUI(0);
					})
					.error(function() {
						app.blockUI(0);
					});
			} else {
				$('#id_agent').val(0);
				$('#id_agent').attr("disabled", "disabled");
			}
		});

		$('#is_done').unbind('change').bind('change', function() {
			var $isContracted = $('.is_contracted');
			var $agentNotes = $('#agent_notes');
			var $select = undefined;
			var val = parseInt($(this).val());
			switch (val) {
				case 0: // in attesa
					$isContracted.attr("disabled", "disabled");
					$agentNotes.attr("disabled", "disabled");
					break;
				case 1: // si
					$isContracted.attr("disabled", "disabled");
					$isContracted.hide();
					$select = $(".is_contracted[data-is-done=" + val + "]");
					$select.removeAttr("disabled");
					$select.show();
					$select.trigger('change');
					$agentNotes.removeAttr("disabled");
					break;
				case 2: // impossibile
					$isContracted.attr("disabled", "disabled");
					$isContracted.hide();
					$select = $(".is_contracted[data-is-done=" + val + "]");
					$select.removeAttr("disabled");
					$select.show();
					$select.trigger('change');
					$agentNotes.removeAttr("disabled");
					break;
				case 3: // rimandato
					$isContracted.attr("disabled", "disabled");
					$agentNotes.removeAttr("disabled");
					break;
				case 4: // richiamare
					$isContracted.attr("disabled", "disabled");
					$isContracted.hide();
					$select = $(".is_contracted[data-is-done=" + val + "]");
					$select.removeAttr("disabled");
					$select.show();
					$select.trigger('change');
					$agentNotes.removeAttr("disabled");
					break;
			}
		});

		$('.is_contracted').unbind('change').bind('change', function() {
			var val = parseInt($(this).val());

			switch (val) {
				case 1:
				case 2:
					$('#mandate_detail').find('input').removeAttr("disabled");
					break;
				default:
					$('#mandate_detail').find('input').attr("disabled", "disabled");
					break;
			}
		});

		$('#id_area').trigger('change');
		$('#is_done').trigger('change');

		$('#area').unbind('change').bind('change', function() {
			localStorage.setItem("area", $(this).val());
			appointment.area = localStorage.getItem("area");
			$('#calendar').fullCalendar( 'refetchEvents' );
		});

		$('#area').val(appointment.area);
		$('#nav-search').show();
	};

	this.onMarkerClick = function(e) {
		if(e && e.target && e.target.options && e.target.options.icon && e.target.options.icon.options && e.target.options.icon.options.customUrl) {
			app.href(e.target.options.icon.options.customUrl);
		}
	};

	this.onSuccess = function() {};

	this.onSuccessEvents = function(view) {
		if(!appointment.callcenter) {
			// recupero solo gli eventi compresi dall'attuale intervallo
			var events = appointment.calendar.fullCalendar('clientEvents');
			var data = [];
			for(var i = 0; i < events.length; i++) {
				if(events[i].start >= view.intervalStart && events[i].end <= view.intervalEnd) {
					data.push(events[i]);
				}
			}

			if(appointment.map) {
				appointment.map.removeMarkers();
			}

			appointment.makeMap('map');
			if(data && data.length) {
				var latLngs = [];
				var icons = [];
				$.each(data, function(i, v) {
					if(v.lat && v.lng /* && v.click */) {
						latLngs.push([v.lat, v.lng]);
						icons.push({
							icon: v.index,
							color: v.color,
							size: 'm',
							customUrl: app.baseUrl + "/#appointment/" + v.id
						});
					}
				});

				if(latLngs.length) {
					appointment.map.markers(latLngs, icons, appointment.onMarkerClick);
				}
			}
		}
	};
	
	this.edit = function(edit, start) {
		if(edit) app.href('#appointment/' + edit);
		else {
			if(start) {
				app.href('#appointment/create?date=' + start.format("DD/MM/YYYY HH:mm"));
			} else {
				app.href('#appointment/create');
			}
		}
	};

	this.makeMap = function(selector) {
		if(!appointment.callcenter && !appointment.map) {
			// prima faccio in modo che il div abbia altezza corretta
			var h = $('[data-map-height=1]').height();
			$('#map').css('height', h + 'px');
			appointment.map = new map(selector);
			appointment.map.init();
		}
	};
	
	this.drawMarker = function() {
		if(!appointment.callcenter) {
			var lat = $('#lat').val();
			var lng = $('#lng').val();
			if(lat && lng) {
				appointment.map.markers(
					[[lat, lng]],
					[{icon: 'embassy', color: '#b0b', size: 'm'}]
				);
			} else {
				var indirizzo = $('#address').val();
				var zip = $('#zip').val();
				var city = $('#city').val();
				var province = $('#province').val();
				var region = $('#region').val();

				var address = indirizzo + ", " + zip + ", " + city + ", " + province + ", " + region;
				map.geocoding(address, function(data) {
					if(data.length) {
						appointment.map.markers(
							[[data[0].Y, data[0].X]],
							[{icon: 'embassy', color: '#b0b', size: 'm'}]
						);
					}
				});
			}
		}
	};

	this.refreshCalendar = function() {
		$('#calendar').fullCalendar( 'refetchEvents' );
	}
};

var appointmentGrid = function(gridId) {
	var appointmentGrid = this;

	this.gridId = gridId;

	this.bindEvents = function () {

		$('[data-interaction=edit]').unbind('click').bind('click', function (e) {
			var id = $("#" + appointmentGrid.gridId).jqGrid('getGridParam', 'selrow') || 0;

			if(!id) {
				app.warning("Devi selezionare una riga");
				return;
			}
			app.href('#appointment/' + id);
		});

		$('[data-interaction=clearFilterWrapGrid]').unbind('click').bind('click', function (e) {
			var url = $(this).attr("data-url");

			app.href(url);
		});

        $('[data-interaction=esporta]').unbind('click').bind('click', function (e) {
			var dataDa = $("#datada").val();
			var dataA = $("#dataa").val();

			window.location.href = app.baseUrl + "/appointment/export?data=1&sord=asc&rows=-1&datada=" + dataDa + "&dataa=" + dataA;
        });
	};

	this.bindEvents();

};
