var contract = function(){

    this.init = function () {
        this.bindEvents();
    };


    this.bindEvents = function(){
        /**
         * Scorre gli elementi, e per ciascuno trova il suo <button> che lo contiene. sposta gli attributi data-interaction al padre
         */
        $("[data-interaction=dropzoneizerParent]").each(function () {
            var btnParent = $(this).parents("button");
            btnParent.attr("data-url", $(this).attr("data-url"));
            btnParent.attr("data-interaction", "dropzone");
            btnParent = $(this).parents("span");
            btnParent.attr("data-url", $(this).attr("data-url"));
            btnParent.attr("data-interaction", "dropzone");
        });

        $("[data-interaction=dropzone]").each(function () {
            var url = $(this).attr("data-url");

            if (!$(this).attr('dropzoned')) {
                $(this).attr('dropzoned', 'true');

                $(this).dropzone({
                    url: url,
                    headers: $.ajaxSetup().headers,
                    previewTemplate: '<div style="display: none;"></div>',
                    acceptedFiles: ".xlsx",
                    timeout: 600000,
                    sending : function()
                    {
                      app.block(1);
                    },
                    success: function (file, data) {
                        app.block(0);

                        $('.dz-preview').detach();
                        if (data.response) {
                            app.reload();
                        } else {
                            app.warning(data.message);
                        }

                    },
                    error: function (file, data) {
                        app.block(0);
                    }
                });
            }
        });
    }
};