var agent = function(gridId) {
    var agent = this;

	this.gridId = gridId;

    this.bindEvents = function () {

        $('[data-interaction=editAgent]').unbind('click').bind('click', function (e) {
            app.block(1);
            app.formSubmit("agent", $("[data-interaction=frmAgent]"), {}, function (data) {
                if (data.response) {
                    app.href('#agent/' + data.message);
                } else {
                    app.error(data.message);
                }
                app.block(0);
            }, function(){
                app.block(0);
            });
        });

        $('[data-interaction="deleteAgent"]').unbind('click').bind('click', function (e) {
            var id = $(this).attr("data-id");
            $.delete("agent/"+id, function(data){
                if (data.response) {
                    app.href("#agent");
                } else {
                    app.error(data.message);
                }
            });
        });

        $('[data-interaction="createAccount"]').unbind('click').bind('click', function (e) {
            var url = $(this).attr("data-url");
            var id = $(this).attr("data-id");

            app.href(url + "?agent=" + id);
        });

		$('[data-interaction=edit]').unbind('click').bind('click', function() {
			var url = $(this).attr('data-url');
			var id = $("#" + agent.gridId).jqGrid('getGridParam', 'selrow') || 0;

			if(!id) {
				app.warning("Devi selezionare una riga");
				return;
			}

			app.href(url + "/" + id);
		});
    };

	this.bindEvents();

};