var callcenter = function(gridId) {
    var callcenter = this;

    this.gridId = gridId;

    this.bindEvents = function () {

        $('[data-interaction=editCallcenter]').unbind('click').bind('click', function (e) {
            app.block(1);
            app.formSubmit("callcenter", $("[data-interaction=frmCallcenter]"), {}, function (data) {
                if (data.response) {
                    app.href('#callcenter/' + data.message);
                } else {
                    app.error(data.message);
                }
                app.block(0);
            }, function(){
                app.block(0);
            });
        });

        $('[data-interaction="deleteCallcenter"]').unbind('click').bind('click', function (e) {
            var id = $(this).attr("data-id");
            $.delete("callcenter/"+id, function(data){
                if (data.response) {
                    app.href("#callcenter");
                } else {
                    app.error(data.message);
                }
            });
        });

		$('[data-interaction=edit]').unbind('click').bind('click', function() {
			var url = $(this).attr('data-url');
			var id = $("#" + callcenter.gridId).jqGrid('getGridParam', 'selrow') || 0;

			if(!id) {
				app.warning("Devi selezionare una riga");
				return;
			}

			app.href(url + "/" + id);
		});

        $('[data-interaction="createAccount"]').unbind('click').bind('click', function (e) {
            var url = $(this).attr("data-url");
            var id = $(this).attr("data-id");

            app.href(url + "?callcenter=" + id);
        });
        
    };

    this.bindEvents();
};

