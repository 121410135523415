var planning = function() {
	var planning = this;
	this.agent = -1;
	this.init = function() {
		var a = new appointment(false);
		a.manager = 1;

		a.onMarkerClick = function(e) {
			if(planning.agent != -1) {
				var id_agent = planning.agent;
				// controllo di aver settato la customUrl per sapere dove fare la chiamata Ajax per l'aggiornamento del id_agent
				if(e && e.target && e.target.options && e.target.options.icon && e.target.options.icon.options && e.target.options.icon.options.customUrl) {
					app.blockUI(1);
					$.put(e.target.options.icon.options.customUrl.replace("#", ""), {id_agent: id_agent})
						.success(function(data) {
							if(data.response) {
								a.calendar.fullCalendar('refetchEvents');
							} else {
								console.warn("Problema");
							}
							app.blockUI(0);
						})
						.error(function() {
							console.error("Problema");
							app.blockUI(0);
						});
				}
			} else {
				alert('Devi selezionare un agente in alto per poter assegnare l\'appuntamento');
			}
		};

		a.onSuccess = function(data) {
			$.each(data, function(i, v) {
				var color = $('[data-id=' + v.agentId + ']').attr('data-color');
				if(!color) {
					color = "#ABBAC3";
				}
				data[i].color = color;
			});
		};

		a.onSuccessEvents = function(view) {
			// nascondo i bottoni
			$('[data-has-area=1]').hide();

			// recupero solo gli eventi compresi dall'attuale intervallo
			var events = a.calendar.fullCalendar('clientEvents');
			var data = [];
			for(var i = 0; i < events.length; i++) {
				if(events[i].start >= view.intervalStart && events[i].end <= view.intervalEnd) {
					data.push(events[i]);
				}
			}

			if(a.map) {
				a.map.removeMarkers();
			}

			a.makeMap('map');
			if(data && data.length) {
				var latLngs = [];
				var icons = [];
				$.each(data, function(i, v) {
					if(v.lat && v.lng) {
						// coloro del colore giusto prendendo dal pulsante che ha il colore corretto
						var color = $('[data-id=' + v.agentId + ']').attr('data-color');
						if(!color) {
							color = "#ABBAC3";
						}
						data[i].color = color;
						v.color = color;

						if(v.click) {
							latLngs.push([v.lat, v.lng]);
							icons.push({
								icon: v.index,
								color: v.color,
								size: 'm',
								customUrl: app.baseUrl + "/#appointment/" + v.id
							});
						}

						$('[data-area=' + v.areaId + ']').show();
					}
				});

				if(latLngs.length) {
					a.map.markers(latLngs, icons, a.onMarkerClick);
				}
			}
		};

		a.init();

		planning.bindEvents();
	};

	this.bindEvents = function() {
		$('[data-interaction=selezionaAgente]').unbind('click').bind('click', function() {
			var id = parseInt($(this).attr('data-id'));

			$('[data-interaction=selezionaAgente]').removeClass('agent-selected');

			if(id == planning.agent) planning.agent = -1;
			else {
				planning.agent = id;
			}

			$('[data-id=' + planning.agent + ']').addClass('agent-selected');
		});
	};
};