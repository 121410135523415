var document_group = function(gridId) {
    var document_group = this;

    this.gridId = gridId;

    this.bindEvents = function () {

        $('[data-interaction=update]').unbind('click').bind('click', function (e) {
            app.block(1);
            app.formSubmit("document_group", $("[data-interaction=frmDocumentGroup]"), {}, function (data) {
                if (data.response) {
                    app.href('#document_group/' + data.message);
                } else {
                    app.error(data.message);
                }
                app.block(0);
            }, function() {
                app.block(0);
            });
        });

        $('[data-interaction=edit]').unbind('click').bind('click', function() {
            var url = $(this).attr('data-url');
            var id = $("#" + document_group.gridId).jqGrid('getGridParam', 'selrow') || 0;

            if(!id) {
                app.warning("Devi selezionare una riga");
                return;
            }

            app.href(url + "/" + id);
        });
    };

    this.bindEvents();

};