var user = function(gridId) {
	var user = this;

	this.gridId = gridId;
	this.bindEvents = function() {
		$('[data-interaction=edit]').unbind('click').bind('click', function() {
			var url = $(this).attr('data-url');
			var id = $("#" + user.gridId).jqGrid('getGridParam', 'selrow') || 0;

			if(!id) {
				app.warning("Devi selezionare una riga");
				return;
			}

			app.href(url + "/" + id);
		});
		
		$('[data-interaction=update]').unbind('click').bind('click', function() {
			var url = $(this).attr("data-url");

			app.block(1);
			app.formSubmit(url, "#" + user.gridId, {}, function(data) {
				if(data.response) {
					app.href(url + "/" + data.message);
				} else {
					app.warning("", data.message);
				}
				app.block(0);
			}, function() {
				app.block(0);
			});
		});

		$('[data-interaction=delete]').unbind('click').bind('click', function() {

		});

		$('[data-interaction=loginAs]').unbind('click').bind('click', function() {


		});

	};

	this.bindEvents();
};