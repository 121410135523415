var area = function(gridId) {
	var area = this;

	this.gridId = gridId;

    this.bindEvents = function () {

        $('[data-interaction=id_city_select]').unbind('change').bind('change', function() {
           alert($('[data-interaction=id_city_select]').val());
            var id =  $('[data-interaction=id_city_select]').val();
            var url = "appointment/getCityDetail";
            $.get(url + "?id=" + id)
                .success(function(data) {
                    //console.log(data);
                    if(data.response) {
                        /*if ($("#cap").val() == "") {
                            $("#cap").val(data.message.cap);
                        }

                        var idProvince = $("#id_province").val() || 0;
                        if (idProvince != 0) {
                            $("#id_province").val(data.message.idProvincia);
                            $("#id_province").trigger("chosen:updated");
                        }

                        var idRegione = $("#id_region").val() || 0;
                        if (idProvince != 0) {
                            $("#id_region").val(data.message.idRegione);
                            $("#id_region").trigger("chosen:updated");
                        }*/

                    } else {
                        app.error(data.message);
                        app.blockUI(0);
                    }
                })
                .error(function() {
                    app.blockUI(0);
                });
        });

        $('[data-interaction=editArea]').unbind('click').bind('click', function (e) {
            app.block(1);
            app.formSubmit("area", $("[data-interaction=frmArea]"), {}, function (data) {
                if (data.response) {
                    app.href('#area/' + data.message);
                } else {
                    app.error(data.message);
                }
                app.block(0);
            }, function(){
                app.block(0);
            });
        });

        $('[data-interaction="deleteArea"]').unbind('click').bind('click', function (e) {
            if (confirm("Procedere con l'eliminazione dell'area?")) {
                var id = $(this).attr("data-id");
                $.delete("area/"+id, function(data){
                    if (data.response) {
                        app.href("#area");
                    } else {
                        app.error(data.message);
                    }
                });
            }
        });

		$('[data-interaction=edit]').unbind('click').bind('click', function() {
			var url = $(this).attr('data-url');
			var id = $("#" + area.gridId).jqGrid('getGridParam', 'selrow') || 0;

			if(!id) {
				app.warning("Devi selezionare una riga");
				return;
			}

			app.href(url + "/" + id);
		});

    };

	this.bindEvents();

};