var report = {
    loadReport: function(reportName, filters, output) {
        switch (output){
            case 'html':

                $.post(app.baseUrl+reportName, filters, function(data) {
                    $('.reportContent').html(data);
                });
                break;
            case 'csv':
                window.open('?p=report&a=' + reportName + '&data=1&output=csv&' + lib.queryStringSerialize(filters));
                break;
            case 'emailpdf':

                dontShowLoader =  1;
                format =  'pdf';

                skipSave =  0;

            function stampa() {
                if (!dontShowLoader)
                    loader.download(1);
                var dataDa = $("#dataDa").val();
                var dataA = $("#dataA").val();
                var idSoggetto = $("#idSoggetto").val();

                $.post("?p=report&a=getJSONDataPrimaNota", {
                        dataDa: dataDa,
                        dataA: dataA,
                        idSoggetto: idSoggetto
                    })
                    .success(function (data) {
                        if (data.response) {
                            var deleteRemoteFile = 1;

                            /**
                             * TODO Da fixare, su mobile se eliminiamo il file remoto non effettua + download
                             */
                            if (app.platform.isMobile())
                                deleteRemoteFile = 0;

                            if(confirm("Inviare il report via mail?")) {
                                var fileName = data.message;
                                $.post('?p=report&a=primaNotaEmailSend', {primanota_file: fileName}, function(data){
                                    $('#mymodal .modal-body').html(data);
                                    $('#mymodal h4.modal-title').html('Inserire l\'indirizzo email destinatario');
                                    $('#mymodal .btn-primary').text('Invia');
                                    $('#mymodal .btn-default').text('Annulla');

                                    $('#mymodal .btn-default').unbind("click").bind('click', function () {
                                        $('#mymodal .close').trigger('click');
                                    });
                                    $('#mymodal .btn-primary').removeAttr("disabled");
                                    $('#mymodal .btn-primary').unbind("click").bind('click', function () {
                                        $(this).attr("disabled", "disabled");
                                        $.post('?p=report&a=primaNotaEmailSend&sendEmail=1', {
                                            primanota_file: fileName,
                                            primanota_sendmail: $('#primanota-sendmail').val(),
                                            primanota_sendmail_commercialista: $('#primanota-sendmail-commercialista').val(),
                                            primanota_sendmail_subject: $('#primanota-sendmail-subject').val(),
                                            primanota_sendmail_body: $('#primanota-sendmail-body').html(),
                                        }, function (data) {
                                            alert(data.message);

                                            if (data.response) {
                                                $('#mymodal .close').trigger('click');
                                            }

                                            $('#mymodal .btn-primary').removeAttr("disabled");
                                        });
                                    });

                                    $('#mymodal').modal('show');
                                    $('#mymodal .btn-primary').show();
                                });
                            }else{
                                window.open(
                                    data.message + "&d=" + deleteRemoteFile, // effettua la cancellazione del file
                                    "_blank"
                                );
                            }

                        } else {
                            if (!dontShowLoader)
                                loader.download(0);
                            alert("Errore nella richiesta di Stampa");
                        }
                        if (!dontShowLoader)
                            loader.download(0);
                    })
                    .error(function (data) {
                        if (!dontShowLoader)
                            loader.download(0);
                        alert("Errore nella richiesta di Stampa");
                    });

            }

                stampa();


                break;
        }

    }
};