/**
 * This plug-in for DataTables represents the ultimate option in extensibility
 * for sorting date / time strings correctly. It uses
 * [Moment.js](http://momentjs.com) to create automatic type detection and
 * sorting plug-ins for DataTables based on a given format. This way, DataTables
 * will automatically detect your temporal information and sort it correctly.
 *
 * For usage instructions, please see the DataTables blog
 * post that [introduces it](//datatables.net/blog/2014-12-18).
 *
 * @name Ultimate Date / Time sorting
 * @summary Sort date and time in any format using Moment.js
 * @author [Allan Jardine](//datatables.net)
 * @depends DataTables 1.10+, Moment.js 1.7+
 *
 * @example
 *    $.fn.dataTable.moment( 'HH:mm MMM D, YY' );
 *    $.fn.dataTable.moment( 'dddd, MMMM Do, YYYY' );
 *
 *    $('#example').DataTable();
 */

(function (factory) {
	if (typeof define === "function" && define.amd) {
		define(["jquery", "moment", "datatables"], factory);
	} else {
		factory(jQuery, moment);
	}
}(function ($, moment) {

	$.fn.dataTable.moment = function ( format, locale ) {
		var types = $.fn.dataTable.ext.type;

		// Add type detection
		types.detect.unshift( function ( d ) {
			// Strip HTML tags if possible
			if ( d && d.replace ) {
				d = d.replace(/<.*?>/g, '');
			}

			// Null and empty values are acceptable
			if ( d === '' || d === null ) {
				return 'moment-'+format;
			}

			return moment( d, format, locale, true ).isValid() ?
			'moment-'+format :
				null;
		} );

		// Add sorting method - use an integer for the sorting
		types.order[ 'moment-'+format+'-pre' ] = function ( d ) {
			if ( d && d.replace ) {
				d = d.replace(/<.*?>/g, '');
			}
			return d === '' || d === null ?
				-Infinity :
				parseInt( moment( d, format, locale, true ).format( 'x' ), 10 );
		};
	};

}));

var dataTableLegacy = {
	idTable: "",
	subTableTemplate: "subTableTemplate",
	subBodyTemplate: "subBodyTemplate",
	table: undefined,
	init: function(idTable, columns, filterColumns, lenghtMenu, fixedHeader, csv, pdf, footerCallback) {
		fixedHeader = fixedHeader || false;
		csv = csv || false;
		pdf = pdf || false;
		dataTableLegacy.idTable = idTable || "";
		columns = columns || undefined;
		filterColumns = filterColumns || undefined;
		lenghtMenu = lenghtMenu || [[10, 50, 100, -1], ["10", "50", "100", "Tutti"]];
		footerCallback = footerCallback || function() {};

		var $table = $("#" + dataTableLegacy.idTable);

		// titolo stampato
		var title = $table.attr("data-title");
		if(!title) title = "";

		// funzione per customizzare la stampa pdf
		var customizePrint = $table.attr("data-customizePrint");
		if(!customizePrint) customizePrint = function(doc) {
			var colCount = [];
			$("#" + dataTableLegacy.idTable).find('tbody tr:first-child td').each(function() {
				if($(this).attr('colspan')) {
					for(var i = 1; i <= $(this).attr('colspan'); i++) {
						colCount.push('*');
					}
				} else {
					colCount.push('*');
				}
			});
			if(doc.content[1].table) {
				doc.content[1].table.widths = colCount;
			}
		};

		// funzione per customizzare l'export
		var customizeExport = $table.attr("data-customizeExport");

		var doSort = (columns === undefined || columns == undefined);
		var doFilter = (filterColumns === undefined || filterColumns == undefined);
		if(doSort) columns = [];
		if(doFilter) filterColumns = [];
		var sort = undefined;
		var filter = undefined;
		var type = undefined;
		var baseType = undefined;
		$table.find("thead").find("tr").find("th").each(function(index) {
			type = $(this).attr("data-table-type");
			sort = $(this).attr("data-table-sort");
			filter = $(this).attr("data-table-filter");
			baseType = $(this).attr("data-table-baseType");
			if(!baseType) baseType = 'string';
			if(doSort) {
				if(sort !== undefined && sort != undefined && typeof sort != "undefined" && sort != "false" && sort != "0") {
					if(type) {
						columns.push({"bSortable": true, "orderDataType": type, "type": baseType});
					} else {
						columns.push({"bSortable": true});
					}
				} else {
					columns.push({"bSortable": false});
				}
			}

			if(doFilter) {
				if(filter !== undefined && filter != undefined && typeof filter != "undefined" && filter != "false" && filter != "0") {
					filterColumns[index] = {index: index, type: type};
				}
			}
		});

		filter = $table.find('thead').attr("data-table-enable-filter");
		if(filter !== undefined && filter != undefined && typeof filter != "undefined" && filter != "false" && filter != "0") {
			/**
			 * Ho abilitato i filtri.
			 * Creo una nuova tr dove ci saranno i filtri
			 */
			var n = $table.find("thead").find("tr").find("th").length;
			var $tr = $('<tr></tr>');
			for(var i = 0; i < n; i++) {
				var $th = $('<th data-filter="' + i + '"></th>');
				$tr.append($th);
			}
			var content = $table.find("thead").html();
			$table.find("thead").html($tr);
			$table.find("thead").append(content);
		}

		dataTableLegacy.sorting();

		dataTableLegacy.table = $table.DataTable({
			bAutoWidth: false,
			aoColumns: columns,
			aaSorting: [],
			aLengthMenu: lenghtMenu,
			bLengthChange: true,
			bPaginate: true,
			select: true,
			responsive: true,
			footerCallback: footerCallback,
			language: {
				"lengthMenu": "_MENU_ Righe per pagina",
				"zeroRecords": "Nessun risultato",
				"info": "da _START_ a _END_ su _TOTAL_",
				"infoEmpty": "",
				"infoFiltered": "",
				"search": "Cerca ovunque: "
			},
			initComplete: function() {
				this.api().columns().every(function(i) {
					if(filterColumns[i]) {
						var column = this;
						var $search = undefined;
						if(filterColumns[i].type && filterColumns[i].type == "date") {
							// TODO possibile gestire altri tipi
						}
						$search = $('<select class="chosen-select" style="width: 100%;" data-placeholder=" "><option value=""></option></select>')
							.appendTo($("[data-filter='" + i + "']").empty())
							.on('change', function() {
								var val = $.fn.dataTableutil.escapeRegex(
									$(this).val()
								);
								column.search(val ? '^' + val + '$' : '', true, false).draw();
							});

						column.data().unique().sort().each(function(d) {
							$search.append('<option value="' + d + '">' + d + '</option>')
						});

						$search.chosen({allow_single_deselect:true});

						if(!fixedHeader) {
							$search.each(function() {
								var $this = $(this);
								$this.next().css({'width': '100%'});
							});
						}
					}
				});
			}
		});

		if(fixedHeader) {
			new $.fn.dataTable.FixedHeader( dataTableLegacy.table );
		}

		var buttons = [];

		// buttons.push({
		// 	extend: "colvis",
		// 	text: "<i class='fa fa-search bigger-110 blue'></i> <span class='hidden'>Print</span>",
		// 	className: "btn btn-white btn-primary btn-bold",
		// });

		if(csv) {
			buttons.push({
				extend: "csvHtml5",
				text: "<i class='fa fa-database bigger-110 orange'></i> <span class='hidden'>Export to CSV</span>",
				className: "btn btn-white btn-primary btn-bold",
				fieldBoundary: "",
				charset: false,
				footer: true,
				fieldSeparator: ";",
				customize: eval(customizeExport)
			});
		}

		if(pdf) {
			buttons.push({
				extend: "pdf",
				text: "<i class='fa fa-print bigger-110 grey'></i> <span class='hidden'>Print</span>",
				className: "btn btn-white btn-primary btn-bold",
				autoPrint: false,
				footer: true,
				orientation: 'landscape',
				title: title
				// customize: eval(customizePrint)
			});
		}

		if(csv || pdf) {
			new $.fn.dataTable.Buttons( dataTableLegacy.table, {
				buttons: buttons
			} );
			dataTableLegacy.table.buttons().container().appendTo( $("#" + dataTableLegacy.idTable + "_wrapper").find('.dataTables_filter') );
		}

		dataTableLegacy.bindEvents();
	},

	sorting: function() {
		// date
		$.fn.dataTable.moment( 'DD/MM/YYYY' );

		// numeric
		$.fn.dataTable.ext.order['numeric'] = function  ( settings, col ) {
			return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
				var value = $(td).html().trim();
				value = value.split(",").join("");
				value = value.split("--").join("0");
				return parseFloat(value);
			} );
		};

		// numeric comma
		$.fn.dataTable.ext.order['numeric-comma'] = function  ( settings, col ) {
			return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
				var value = $(td).html().trim();
				value = value.split(".").join("");
				value = value.split(",").join(".");
				value = value.split("--").join("0");
				return parseFloat(value);
			} );
		};
	},

	setSubTable: function(data) {
		var $subTable = $("[data-interaction=" + dataTableLegacy.subTableTemplate + "]").clone();
		var $tmpTable = $('<table></table>');
		for(var i = 0; i < data.length; i++) {
			var $subBody = $('[data-interaction="' + dataTableLegacy.subBodyTemplate + '"]').clone();
			$subBody.removeAttr("data-interaction");
			$subBody.find('[data-interaction="subTableField"]').each(function() {
				var target = $(this).attr("data-target");
				target = target || "";

				if(data[i][target] !== undefined && data[i][target] != undefined && typeof data[i][target] != "undefined") {
					$(this).html(data[i][target]);
				}
			});
			$tmpTable.append($subBody);
		}

		$subTable.find("tbody").html($tmpTable.html());
		return $subTable.html();
	},

	bindEvents: function() {
		$("#" + dataTableLegacy.idTable).find('thead').find('select').on('change', function() {
			dataTableLegacy.bindEvents();
		});

		$('select[name="ordersTable_length"]').on('change', function() {
			dataTableLegacy.bindEvents();
		});

		$(".paginate_button").click(function() {
			dataTableLegacy.bindEvents();
		});

		$('[data-interaction="subTable"]').unbind("click").bind("click", function() {
			var $tr = $(this).closest('tr');
			var id = $(this).attr("data-id");
			var url = $(this).attr("data-url");
			var callbackName = $(this).attr("data-callback");
			var row = dataTableLegacy.table.row( $tr );

			// verifico se la callback esiste
			var callback = false;
			if (typeof callbackName != 'undefined') {
				if(jQuery.isFunction(callbackName))
					callback = true;
				if(jQuery.isFunction(eval(callbackName)))
					callback = true;
			}

			if(row.child.isShown()) {
				row.child.hide();
				$tr.removeClass('shown');
			} else {
				app.blockUI(1);
				$.post(url, {id: id})
					.success(function(data) {
						if(data.response) {
							if(callback) {
								eval(callbackName)(row, data);
							} else {
								row.child( dataTableLegacy.setSubTable(data.message) ).show();
								$tr.addClass('shown');
							}
						}
						app.blockUI(0);
					})
					.error(function() {
						app.blockUI(0);
					});
			}
		});
	}
};