var mandate = function(gridId) {
	var mandate = this;
	
	this.gridId = gridId;
	
	mandate.init = function() {
		mandate.bindEvents();	
	};
	
	mandate.bindEvents = function() {
		$('[data-interaction=save]').unbind('click').bind('click', function () {
			var url = $(this).attr("data-url");

			app.block(1);
			app.formSubmit(url, $("[data-interaction=frmMandate]"), {}, function (data) {
				if (data.response) {
					app.href(url + "/" + data.message);
				} else {
					app.warning("", data.message);
				}
				app.block(0);
			}, function(data) {
				app.block(0);
				app.warning("", app.parseAjaxError(data));
			});
		});

		$('[data-interaction=delete]').unbind('click').bind('click', function () {
			var url = $(this).attr("data-url");
			var to = $(this).attr("data-url-to");

			$.delete(url, function(data){
				if (data.response) {
					app.href(to);
				} else {
					app.error(data.message);
				}
			});
		});

		$('[data-interaction=edit]').unbind('click').bind('click', function() {
			var url = $(this).attr('data-url');
			var id = $("#" + mandate.gridId).jqGrid('getGridParam', 'selrow') || 0;

			if(!id) {
				app.warning("Devi selezionare una riga");
				return;
			}

			app.href(url + "/" + id);
		});

		$('.chkDocumentoGroup').unbind('click').bind('click', function() {
			var enabled = 0;
			var strEnabled = "Disabbinamento";
			var idDocumentGroup = $(this).attr('data-id-documento-group');
			var idMandate = $("#id").val();
			var url = $(this).attr('data-url');
			if ($(this).is(':checked')) {
				enabled = 1;
				strEnabled = "Abbinamento";
			}
			app.blockUI(1);
			$.post(url, {enabled:enabled, idDocumentGroup:idDocumentGroup, idMandate:idMandate})
				.success(function(data) {
					if(data.response) {
						app.success("Cat. Documenti", strEnabled + " al mandato effettuato con successo");
					} else {
						app.warning("Errore Cat. Documenti", strEnabled + " al mandato terminato in stato di errore " + data.message);
						if (enabled == 1)
							$(this).prop('checked', false);
						else
							$(this).prop('checked', true);
					}
					app.blockUI(0);
				})
				.error(function() {
					app.blockUI(0);
				});
		});
	};
};