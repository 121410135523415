var doc = function(url) {
	var doc = this;

	doc.uploadUrl = url;
	doc.treeData = url;
	doc.bindEvents = function() {
		$('[data-interaction=new]').unbind('click').bind('click', function() {
			var target = $(this).attr("data-target");

			$('#title-box').show();
			$('#group-box').show();
			$('.dz-processing').detach();
			$('.dz-default.dz-message').show();
			$(target).modal();
		});

		$('[data-interaction=close]').unbind('click').bind('click', function() {
			var target = $(this).attr("data-target");

			app.block(0);
			$(target).modal('toggle');
			app.reload();
		});

		$('[data-interaction=delete]').unbind('click').bind('click', function() {
			var url = $(this).attr("data-url");
			var self = this;

			app.block(1);
			$.delete(url)
				.success(function(data) {
					if(data.response) {
						app.success("", "Eliminazione effettuata");
						$(self).closest('tr').detach();
					} else {
						app.warning("", data.message);
					}
					app.block(0);
				})
				.error(function() {
					app.block(0);
					app.error("", "Errore eliminazione documento");
				});
		});

		$('#dropzone').dropzone(
			{
				url: doc.uploadUrl,
				headers: $.ajaxSetup().headers,
				previewTemplate : $('#dropzone-body').html(),
				success: function (file, data) {
					$('#title-box').hide();
					$('#group-box').hide();
					$('#upload-wait').hide();
					if(data.response) {
						$("#upload-success").html(data.message + " - Upload effettuato");
						$("#upload-success").show();
					} else {
						$("#upload-error").html(data.message + " - Upload non effettuato");
						$("#upload-error").show();
					}
				},
				error: function () {
					$("#upload-error").html("Errore upload file - Upload non effettuato");
					$("#upload-error").show();
				}
			}
		);
	};
	
	doc.makeTree = function() {
		if($('#tree').length) {
			var data = JSON.parse(doc.treeData);
			var dataSource = function(options, callback) {
				var $data = null;
				if(!("text" in options) && !("type" in options)){
					// root
					$data = data;
					callback({ data: $data });
					return;
				}
				else if("type" in options && options.type == "folder") {
					// children
					if("additionalParameters" in options && "children" in options.additionalParameters) {
						$data = options.additionalParameters.children || {};
					}
					else $data = {}; //no data
				}

				callback({ data: $data });
			};

			$('#tree').ace_tree({
				dataSource: dataSource,
				multiSelect: false,
				cacheItems: true,
				'open-icon': 'ace-icon tree-minus',
				'close-icon': 'ace-icon tree-plus',
				'itemSelect': true,
				'folderSelect': false,
				'selected-icon' : 'ace-icon fa fa-check',
				'unselected-icon' : 'ace-icon fa fa-times',
				loadingHTML : '<div class="tree-loading"><i class="ace-icon fa fa-refresh fa-spin blue"></i></div>'
			});

			$('#tree').on('selected.fu.tree', function (event, data) {
				// do something with data: { selected: [array], target: [object] }
				if(data.selected && data.selected.length && data.selected[0].additionalParameters) {
					app.locationHref('#document/' + data.selected[0].additionalParameters.id, 1);
				}
			});
		}
	};

	doc.bindEvents();
	doc.makeTree();
};
