var map = function(selector) {
	var map = this;
	this.m = undefined;
	this.imgPath = 'imgs/leaflet';
	this.token = 'pk.eyJ1Ijoid2Vjb25zdHVkaW8iLCJhIjoiY2loZW1lN2ZxMDA0NXZrbTlzYzFrZHdmbSJ9.t1KK25T9YStL4Wv_AgPyvw';
	this.mks = [];

	this.check = function() {
		if(L == undefined) {
			console.error('Leaflet mancante');
			return false;
		}

		if(L.MakiMarkers == undefined) {
			console.error("MakiMarkers mancante");
			return false;
		}

		return true;
	};

	/**
	 * Inizializza la mappa
	 *
	 * @param selector string Selettore senza #
	 * @param latLng array Contengono latitudine e longitudine iniziale
	 */
	this.init = function() {
		if(!map.check) return;

		if(typeof selector == "string") {
			map.m = L.map(selector);
		} else {
			console.error('Il selettore deve essere di tipo stringa senza #');
			return;
		}

		L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			maxZoom: 18,
			// id: selector,
			accessToken: map.token,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		}).addTo(map.m);

		// setto il path delle immagini per i markers
		L.Icon.Default.imagePath = map.imgPath;

		// setto il token per MakiMarkers
		L.MakiMarkers.accessToken = map.token;
	};

	this.setView = function(latLng, zoom) {
		map.m.setView(latLng, zoom);
	};

	/**
	 * Si occupa di disegnare dei marker sulla mappa con l'array di latLngs passato
	 *
	 * @param latLngs array Latitudini e Longitudini da marcare [[lat, lng], [lat, lng]]
	 * @param icons array Icone che rappresentato ognuno dei marker [{icon: ..., color: ..., size: 's m l'}, {...}]
	 * @param callback function click callback
	 */
	this.markers = function(latLngs, icons, callback) {
		if(!map.check) return;

		$.each(latLngs, function(i, v) {
			var icon = L.MakiMarkers.icon(icons[i]);
			var mk = L.marker(v, {icon: icon});
			mk.addTo(map.m);
			mk.on('click', callback);
			map.mks.push(mk);
		});

		map.m.fitBounds(new L.featureGroup(map.mks).getBounds());

	};

	this.removeMarkers = function() {
		$.each(map.mks, function(i) {
			map.m.removeLayer(map.mks[i]);
		});

		map.mks = [];
	};

	this.remove = function() {
		if(map.m) {
			console.log("Removing");
			map.m.remove();
			map.m = undefined;
			map.mks = [];
		}
	}
};

map.geocoding = function(address, callback) {
	if(L.GeoSearch == undefined) {
		console.error("GeoSearch mancante");
		return;
	}

	if(L.GeoSearch.Provider.Google == undefined) {
		console.error("Provider Google mancante");
		return;
	}

	var googleGeocodeProvider = new L.GeoSearch.Provider.Google();

	googleGeocodeProvider.GetLocations(address, function ( data ) {
		callback(data);
	});
};